const translation = {
  EN: {
    grid_connected: "Grid Connected",
    off_grid: "Off-Grid",
    input: "Input",
    solar_radiation_database: "Solar radiation database",
    installed_peak_pv_power_wp: "Installed peak PV power [Wp]",
    pv_technology: "PV technology",
    installed_peak_pv_power_kwp: "Installed peak PV power [kWp]",
    system_loss: "System loss [%]",
    fixed_mounting_options: "Fixed Mounting Options",
    mounting_position: "Mounting position",
    slope: "Slope [°]",
    azimuth: "Azimuth [°]",
    optimize: "Optimize",
    pv_electricity_price: "PV electricity price",
    pv_system_cose: "PV system cost (your currency)",
    interest: "Interest [%/year]",
    lifetime: "Lifetime [years]",
    battery_capacity: "Battery capacity [Wh]",
    discharge_cutoff_limit: "Discharge cutoff limit [%]",
    consumption_per_day: "Consumption per day [Wh]",
    visualize_result: "Visualize result",
    slope_angle: "Slope angle [°]",
    azimuth_angle: "Azimuth angle [°]",
    yearly_pv_energy_production: "Yearly PV energy production [kWh]",
    yearly_in_plane_irradiation: "Yearly in-plane irradiation [kWh/m2]",
    year_to_year_variability: "Year-to-year variability [kWh]",
    changes_in_output_due_to: "Changes in output due to",
    angle_of_incidence: "Angle of incidence [%]",
    spectral_effects: "Spectral effects [%]",
    temperature_and_low_irradiance: "Temperature and low irradiance [%]",
    total_loss: "Total loss [%]",
    pv_electricity_cost: "PV electricity cost [per kWh]",
    percentage_days_with_full_battery: "Percentage days with full battery [%]",
    percentage_days_with_empty_battery: "Percentage days with empty battery [%]",
    average_energy_not_captured: "Average energy not captured [Wh]",
    average_energy_missing: "Average energy missing [Wh]",
    simulation_outputs: "Simulation Outputs",
    monthly_performance: "Monthly Performance",
    percentile: "Percentile",
    pv_technology_dropdown_unknown: "Unknown",
    mounting_position_dropdown_free_standing: "Free-standing",
    mounting_position_dropdown_roof_added_building_integrated: "Roof added / Building integrated",
    optimize_dropdown_none: "None",
    optimize_dropdown_slope: "Slope",
    optimize_dropdown_slope_and_azimuth: "Slope and Azimuth",
    monthly_performance_graph_pv_output: "PV output [kWh]",
    monthly_performance_graph_radiation: "Radiation [kWh/m2]",
    monthly_performance_graph_energy_output: "Energy output [Wh]",
    monthly_performance_graph_energy_not_captured: "Energy not captured [Wh]",
    monthly_performance_graph_days_with_battery_full: "Days with battery full [%]",
    monthly_performance_graph_days_with_battery_empty: "Days with battery empty [%]",
    percentile_graph_charge_state_frequency: "Charge state frequency [%]",
    tutorial1: (
      <>
        Select an area on the map. After confirmation, press “Visualize Result.”
        <br />
        By selecting the area on the map, an optimized database will automatically be reflected on
        the solar radiation database field.
      </>
    ),
    tutorial2: (
      <>
        ・How to calculate the performance of a grid connected PV system
        <br />
        To calculate the performance of a grid connected PV system, select a location (coordinate)
        of your solar energy system on the map, then input “PV technology,” “Installed peak PV
        power,” “System loss,” “Mounting position,” “Slope” and “Azimuth”. Based on these
        conditions, the following items will be shown on the graph.
        <br />
        <br />
        ＊Monthly energy production (1 year)
        <br />
        ＊Monthly radiation (1 year)
      </>
    ),
    tutorial3: (
      <>
        ・How to calculate the performance of an off-grid PV system
        <br />
        To calculate the performance of an off-grid PV system, select a location (coordinate) of
        your solar energy system on the map, then input “Installed peak PV power,” “Battery
        capacity,” “Discharge cutoff limit,” “Consumption per day,” “Slope” and “Azimuth”. Based on
        these conditions, the following items will be shown on the graph.
        <br />
        <br />
        ＊Monthly statistics of energy production (1 year) and energy not captured (1 year)
        <br />
        ＊Monthly statistics of percentage days with full battery and percentage days with empty
        battery (1 year)
        <br />
        ＊Histogram of battery state of charge (1 year)
      </>
    ),
  },
  JA: {
    grid_connected: "グリッド接続",
    off_grid: "オフグリッド",
    input: "入力項目",
    solar_radiation_database: "日射量データベース",
    installed_peak_pv_power_wp: "公称最大出力[Wp]",
    pv_technology: "PVテクノロジー",
    installed_peak_pv_power_kwp: "公称最大出力[kWp]",
    system_loss: "システムロス [%]",
    fixed_mounting_options: "固定設置条件",
    mounting_position: "設置位置",
    slope: "傾斜角度 [°]",
    azimuth: "方位角度 [°]",
    optimize: "最適化する",
    pv_electricity_price: "PV電気料金",
    pv_system_cose: "PVシステム料金(ご利用通貨)",
    interest: "金利 [%/年]",
    lifetime: "寿命 [年]",
    battery_capacity: "バッテリー容量 [Wh]",
    discharge_cutoff_limit: "放電カットオフ限度 [%]",
    consumption_per_day: "1日あたりの消費電力量 [Wh]",
    visualize_result: "結果の可視化",
    slope_angle: "傾斜角度 [°]",
    azimuth_angle: "方位角度 [°]",
    yearly_pv_energy_production: "年間太陽光発電量 [kWh]",
    yearly_in_plane_irradiation: "年間面内照射量 [kWh/m2]",
    year_to_year_variability: "年間変動 [kWh]",
    changes_in_output_due_to: "出力変化の要因",
    angle_of_incidence: "入射角 [%]",
    spectral_effects: "スペクトル効果 [%]",
    temperature_and_low_irradiance: "温度および低照射量 [%]",
    total_loss: "合計損失 [%]",
    pv_electricity_cost: "1kWhあたりのPV電気コスト [per kWh]",
    percentage_days_with_full_battery: "バッテリーが100%になった日数の割合 [%]",
    percentage_days_with_empty_battery: "バッテリーが0%になった日数の割合 [%]",
    average_energy_not_captured: "蓄電されなかった発電量の平均 [Wh]",
    average_energy_missing: "蓄電池から使用された電力の平均 [Wh]",
    simulation_outputs: "シミュレーション結果",
    monthly_performance: "パフォーマンス（月間）",
    percentile: "パーセンタイル",
    pv_technology_dropdown_unknown: "不明",
    mounting_position_dropdown_free_standing: "据え置き型（架台）",
    mounting_position_dropdown_roof_added_building_integrated: "屋根一体型 / 建物統合型",
    optimize_dropdown_none: "なし",
    optimize_dropdown_slope: "傾斜角度",
    optimize_dropdown_slope_and_azimuth: "傾斜角度 / 方位角度",
    monthly_performance_graph_pv_output: "PV出力 [kWh]",
    monthly_performance_graph_radiation: "日射量 [kWh/m2]",
    monthly_performance_graph_energy_output: "エネルギー出力 [Wh]",
    monthly_performance_graph_energy_not_captured: "蓄電されなかった発電量 [Wh]",
    monthly_performance_graph_days_with_battery_full: "バッテリーが100%になった日数 [%]",
    monthly_performance_graph_days_with_battery_empty: "バッテリーが0%になった日数 [%]",
    percentile_graph_charge_state_frequency: "充電状態の割合 [%]",
    tutorial1: (
      <>
        対象の場所をマップで指定し、入力項目の確認後、「結果の可視化」を押してください。
        <br />
        入力項目の日射量データベースは、対象の場所をマップで指定すると最適なデータベースが自動で反映されます。
      </>
    ),
    tutorial2: (
      <>
        ・グリッドPVシステムのパフォーマンス計算
        <br />
        パフォーマンスを計算するためには、マップ上で太陽光パネルの設置位置(座標)を指定し、「PVテクノロジー」、「公称最大出力」、「システムロス」、「設置位置」、「傾斜角度」、「方位角度」について入力が必要です。この条件を元に、以下の項目がグラフで表示されます。
        <br />
        <br />
        ＊発電量(1年分)の月次統計
        <br />
        ＊日射量(1年分)の月次統計
      </>
    ),
    tutorial3: (
      <>
        ・オフグリッドPVシステムのパフォーマンス計算
        <br />
        パフォーマンスを計算するためには、マップ上で太陽光パネルの設置位置(座標)を指定し、「公称最大出力」、「バッテリー容量」、「放電カットオフ限度」、「1日あたりの消費電力量」、「傾斜角度」、「方位角度」について入力が必要です。この条件を元に、以下の項目がグラフで表示されます。
        <br />
        <br />
        ＊発電量(1年分)と蓄電されなかった発電量(1年分)の月次統計
        <br />
        ＊バッテリーが100％または0％になった頻度に関する月次統計
        <br />
        ＊バッテリー充電に関するヒストグラム
      </>
    ),
  },
  zhCN: {
    grid_connected: "并网",
    off_grid: "离网",
    input: "输入",
    solar_radiation_database: "太阳辐射数据库",
    installed_peak_pv_power_wp: "安装的峰值光伏功率 [Wp]",
    pv_technology: "光伏技术",
    installed_peak_pv_power_kwp: "安装的峰值光伏功率 [kWp]",
    system_loss: "系统损失 [%]",
    fixed_mounting_options: "固定安装选项",
    mounting_position: "安装位置",
    slope: "坡度 [°]",
    azimuth: "方位 [°]",
    optimize: "优化",
    pv_electricity_price: "光伏电价",
    pv_system_cose: "光伏系统成本（您的货币）",
    interest: "利息 [%/年]",
    lifetime: "寿命 [年]",
    battery_capacity: "电池容量 [Wh]",
    discharge_cutoff_limit: "排放截止限 [%]",
    consumption_per_day: "每天消耗 [Wh]",
    visualize_result: "可视化结果",
    slope_angle: "倾斜角度 [°]",
    azimuth_angle: "方位角 [°]",
    yearly_pv_energy_production: "年光伏发电量 [kWh]",
    yearly_in_plane_irradiation: "年度面内辐照 [kWh/m2]",
    year_to_year_variability: "年复一年的变化 [kWh]",
    changes_in_output_due_to: "由于输出的变化",
    angle_of_incidence: "入射角 [%]",
    spectral_effects: "光谱效应 [%]",
    temperature_and_low_irradiance: "温度和低辐照度 [%]",
    total_loss: "总体损耗 [%]",
    pv_electricity_cost: "光伏发电成本 [per kWh]",
    percentage_days_with_full_battery: "充满电的天数百分比 [%]",
    percentage_days_with_empty_battery: "电池没电的天数百分比 [%]",
    average_energy_not_captured: "未捕获的平均能量 [Wh]",
    average_energy_missing: "平均能量缺失 [Wh]",
    simulation_outputs: "仿真输出",
    monthly_performance: "每月表现",
    percentile: "百分位数",
    pv_technology_dropdown_unknown: "未知",
    mounting_position_dropdown_free_standing: "独立式",
    mounting_position_dropdown_roof_added_building_integrated: "屋顶增加/建筑集成",
    optimize_dropdown_none: "没有任何",
    optimize_dropdown_slope: "坡度 [°]",
    optimize_dropdown_slope_and_azimuth: "坡度 [°] / 方位 [°]",
    monthly_performance_graph_pv_output: "光伏输出 [kWh]",
    monthly_performance_graph_radiation: "辐射 [kWh/m2]",
    monthly_performance_graph_energy_output: "能量输出 [Wh]",
    monthly_performance_graph_energy_not_captured: "未捕获能量 [Wh]",
    monthly_performance_graph_days_with_battery_full: "充满电的日子 [%]",
    monthly_performance_graph_days_with_battery_empty: "电池没电的日子 [%]",
    percentile_graph_charge_state_frequency: "电荷态频率 [%]",
    tutorial1: (
      <>
        在地图上选择一个区域。确认后，按“可视化结果”。
        <br />
        通过在地图上选择区域，优化的数据库将自动反映在太阳辐射数据库字段上。
      </>
    ),
    tutorial2: (
      <>
        ・并网光伏系统的性能计算
        <br />
        为了计算性能，在地图上指定太阳能电池板的安装位置（坐标），“光伏技术”，“标称最大输出”，“系统损耗”，“安装位置”，“倾角”，输入是“方位角”需要。基于此条件，图表中显示以下项目。
        <br />
        <br />
        *每月发电量统计（一年）
        <br />
        *月度太阳辐射统计（一年）
      </>
    ),
    tutorial3: (
      <>
        ・离网光伏系统性能计算
        <br />
        为了计算性能，在地图上指定太阳能电池板的安装位置（坐标），“标称最大输出”，“电池容量”，“放电截止限制”，“每天的电力消耗，”倾斜角度，和“方位角”是必需的。基于此条件，图表中显示以下项目。
        <br />
        <br />
        * 月度统计发电量（1年）和未存储发电量（1年）
        <br />
        * 关于电池电量达到 100% 或 0% 的频率的每月统计数据
        <br />* 电池充电直方图
      </>
    ),
  },
  zhTW: {
    grid_connected: "並網",
    off_grid: "離網",
    input: "輸入",
    solar_radiation_database: "太陽輻射數據庫",
    installed_peak_pv_power_wp: "安裝的峰值光伏功率 [Wp]",
    pv_technology: "光伏技術",
    installed_peak_pv_power_kwp: "安裝的峰值光伏功率 [kWp]",
    system_loss: "系統損失 [%]",
    fixed_mounting_options: "固定安裝選項",
    mounting_position: "安裝位置",
    slope: "坡度 [°]",
    azimuth: "方位 [°]",
    optimize: "優化",
    pv_electricity_price: "光伏電價",
    pv_system_cose: "光伏系統成本（您的貨幣）",
    interest: "利息 [%/年]",
    lifetime: "壽命 [年]",
    battery_capacity: "電池容量 [Wh]",
    discharge_cutoff_limit: "排放截止限 [%]",
    consumption_per_day: "每天消耗 [Wh]",
    visualize_result: "可視化結果",
    slope_angle: "傾斜角 [°]",
    azimuth_angle: "方位角 [°]",
    yearly_pv_energy_production: "年光伏發電量 [kWh]",
    yearly_in_plane_irradiation: "年度面內輻照 [kWh/m2]",
    year_to_year_variability: "年復一年的變化 [kWh]",
    changes_in_output_due_to: "由於輸出的變化",
    angle_of_incidence: "入射角 [%]",
    spectral_effects: "光譜效應 [%]",
    temperature_and_low_irradiance: "溫度和低輻照度 [%]",
    total_loss: "總體損耗 [%]",
    pv_electricity_cost: "光伏發電成本 [per kWh]",
    percentage_days_with_full_battery: "充滿電的天數百分比 [%]",
    percentage_days_with_empty_battery: "電池沒電的天數百分比 [%]",
    average_energy_not_captured: "未捕獲的平均能量 [Wh]",
    average_energy_missing: "平均能量缺失 [Wh]",
    simulation_outputs: "仿真輸出",
    monthly_performance: "每月表現",
    percentile: "百分位數",
    pv_technology_dropdown_unknown: "未知",
    mounting_position_dropdown_free_standing: "獨立式",
    mounting_position_dropdown_roof_added_building_integrated: "屋頂增加/建築集成",
    optimize_dropdown_none: "沒有任何",
    optimize_dropdown_slope: "坡度 [°]",
    optimize_dropdown_slope_and_azimuth: "坡度 [°] / 方位 [°]",
    monthly_performance_graph_pv_output: "光伏輸出 [kWh]",
    monthly_performance_graph_radiation: "輻射 [kWh/m2]",
    monthly_performance_graph_energy_output: "能量輸出 [Wh]",
    monthly_performance_graph_energy_not_captured: "未捕獲能量 [Wh]",
    monthly_performance_graph_days_with_battery_full: "充滿電的日子 [%]",
    monthly_performance_graph_days_with_battery_empty: "電池沒電的日子 [%]",
    percentile_graph_charge_state_frequency: "電荷態頻率 [%]",
    tutorial1: (
      <>
        在地圖上選擇一個區域。確認後，按“可視化結果”。
        <br />
        通過在地圖上選擇區域，優化的數據庫將自動反映在太陽輻射數據庫字段上。
      </>
    ),
    tutorial2: (
      <>
        ・並網光伏系統的性能計算
        <br />
        為了計算性能，在地圖上指定太陽能電池板的安裝位置（坐標），“光伏技術”，“標稱最大輸出”，“系統損耗”，“安裝位置”，“傾角”，輸入是“方位角”需要。基於此條件，圖表中顯示以下項目。
        <br />
        <br />
        *每月發電量統計（一年）
        <br />
        *月度太陽輻射統計（一年）
      </>
    ),
    tutorial3: (
      <>
        ・離網光伏系統性能計算
        <br />
        為了計算性能，在地圖上指定太陽能電池板的安裝位置（坐標），“標稱最大輸出”，“電池容量”，“放電截止限制”，“每天的電力消耗，”傾斜角度，和“方位角”是必需的。基於此條件，圖表中顯示以下項目。
        <br />
        <br />
        * 月度統計發電量（1年）和未存儲發電量（1年）
        <br />
        * 關於電池電量達到 100% 或 0% 的頻率的每月統計數據
        <br />* 電池充電直方圖
      </>
    ),
  },
  KR: {
    grid_connected: "그리드 연결",
    off_grid: "독립형",
    input: "입력",
    solar_radiation_database: "태양 복사 데이터베이스",
    installed_peak_pv_power_wp: "설치된 피크 PV 전력 [Wp]",
    pv_technology: "PV 기술",
    installed_peak_pv_power_kwp: "설치된 피크 PV 전력 [kWp]",
    system_loss: "시스템 손실 [%]",
    fixed_mounting_options: "고정 장착 옵션",
    mounting_position: "장착 위치",
    slope: "기울기 [°]",
    azimuth: "방위각 [°]",
    optimize: "최적화",
    pv_electricity_price: "PV 전기 가격",
    pv_system_cose: "PV 시스템 비용(현재 ---------)",
    interest: "이자 [%/년]",
    lifetime: "평생 [년]",
    battery_capacity: "배터리 용량 [Wh]",
    discharge_cutoff_limit: "배출 차단 한계 [%]",
    consumption_per_day: "일일 소비 [Wh]",
    visualize_result: "결과 시각화",
    slope_angle: "경사각 [°]",
    azimuth_angle: "방위각 [°]",
    yearly_pv_energy_production: "연간 태양광 에너지 생산량 [kWh]",
    yearly_in_plane_irradiation: "연간 면내 조사 [kWh/m2]",
    year_to_year_variability: "해마다 변동성 [kWh]",
    changes_in_output_due_to: "다음으로 인한 출력 변화",
    angle_of_incidence: "입사각 [%]",
    spectral_effects: "스펙트럼 효과 [%]",
    temperature_and_low_irradiance: "온도 및 낮은 조도 [%]",
    total_loss: "총 손실 [%]",
    pv_electricity_cost: "PV 전기 비용 [per kWh]",
    percentage_days_with_full_battery: "배터리가 가득 찬 백분율 일수 [%]",
    percentage_days_with_empty_battery: "배터리가 방전된 일수 백분율 [%]",
    average_energy_not_captured: "포착되지 않은 평균 에너지 [Wh]",
    average_energy_missing: "평균 에너지 누락 [Wh]",
    simulation_outputs: "시뮬레이션 출력",
    monthly_performance: "월간 실적",
    percentile: "백분위수",
    pv_technology_dropdown_unknown: "알려지지 않은",
    mounting_position_dropdown_free_standing: "자유로운 무대",
    mounting_position_dropdown_roof_added_building_integrated: "지붕 추가 / 건물 통합",
    optimize_dropdown_none: "없음",
    optimize_dropdown_slope: "기울기 [°]",
    optimize_dropdown_slope_and_azimuth: "기울기 [°] / 방위각 [°]",
    monthly_performance_graph_pv_output: "PV 출력 [kWh]",
    monthly_performance_graph_radiation: "방사능 [kWh/m2]",
    monthly_performance_graph_energy_output: "에너지 출력 [Wh]",
    monthly_performance_graph_energy_not_captured: "포착되지 않은 에너지 [Wh]",
    monthly_performance_graph_days_with_battery_full: "배터리가 가득 찬 날 [%]",
    monthly_performance_graph_days_with_battery_empty: "배터리가 방전된 일수 [%]",
    percentile_graph_charge_state_frequency: "충전 상태 주파수 [%]",
    tutorial1: (
      <>
        지도에서 지역을 선택합니다. 확인 후 “결과 시각화”를 누릅니다.
        <br />
        지도에서 해당 지역을 선택하면 최적화된 데이터베이스가 일사 데이터베이스 필드에 자동으로
        반영됩니다.
      </>
    ),
    tutorial2: (
      <>
        · 그리드 PV 시스템의 성능 계산
        <br />
        퍼포먼스를 계산하기 위해서는, 맵상에서 태양광 패널의 설치 위치(좌표)를 지정해, 「PV
        테크놀로지」, 「공칭 최대 출력」, 「시스템 로스」, 「설치 위치」, 「경사 각도」, 「방위
        각도」에 대한 입력이 필요합니다. 이 조건에 따라 다음 항목이 그래프로 표시됩니다.
        <br />
        <br />
        ＊발전량(1년분)의 월차 통계
        <br />
        ＊일사량(1년분)의 월차 통계
      </>
    ),
    tutorial3: (
      <>
        · 오프 그리드 PV 시스템의 성능 계산
        <br />
        퍼포먼스를 계산하기 위해서는, 맵상에서 태양광 패널의 설치 위치(좌표)를 지정해, 「공칭 최대
        출력」, 「배터리 용량」, 「방전 컷오프 한도」, 「일일의 소비 전력량」,「경사각」,
        「방위각」에 대한 입력이 필요합니다. 이 조건에 따라 다음 항목이 그래프로 표시됩니다.
        <br />
        <br />
        ＊발전량(1년분)과 축전되지 않은 발전량(1년분)의 월별 통계
        <br />
        ＊배터리가 100% 또는 0%가 된 빈도에 관한 월별 통계
        <br />
        ＊배터리 충전에 관한 히스토그램
      </>
    ),
  },
  TH: {
    grid_connected: "เชื่อมต่อกริดแล้ว",
    off_grid: "นอกตาราง",
    input: "ป้อนข้อมูล",
    solar_radiation_database: "ฐานข้อมูลรังสีอาทิตย์",
    installed_peak_pv_power_wp: "กำลัง PV สูงสุดที่ติดตั้ง [Wp]",
    pv_technology: "เทคโนโลยีเซลล์แสงอาทิตย์",
    installed_peak_pv_power_kwp: "กำลังไฟฟ้าสูงสุดที่ติดตั้งไว้ [kWp]",
    system_loss: "การสูญเสียระบบ [%]",
    fixed_mounting_options: "ตัวเลือกการติดตั้งแบบคงที่",
    mounting_position: "ตำแหน่งการติดตั้ง",
    slope: "ความชัน [°]",
    azimuth: "ราบ [°]",
    optimize: "เพิ่มประสิทธิภาพ",
    pv_electricity_price: "ราคาไฟฟ้า PV",
    pv_system_cose: "ค่าใช้จ่ายระบบ PV (ปัจจุบันของคุณ ---------)",
    interest: "ดอกเบี้ย [%/ปี]",
    lifetime: "อายุการใช้งาน [ปี]",
    battery_capacity: "ความจุแบตเตอรี่ [Wh]",
    discharge_cutoff_limit: "ขีดจำกัดการตัดออก [%]",
    consumption_per_day: "การบริโภคต่อวัน [Wh]",
    visualize_result: "แสดงผลลัพธ์",
    slope_angle: "มุมลาด [°]",
    azimuth_angle: "มุมราบ [°]",
    yearly_pv_energy_production: "การผลิตพลังงาน PV ประจำปี [kWh]",
    yearly_in_plane_irradiation: "การฉายรังสีบนเครื่องบินประจำปี [kWh/m2]",
    year_to_year_variability: "ความแปรปรวนปีต่อปี [kWh]",
    changes_in_output_due_to: "การเปลี่ยนแปลงในเอาต์พุตเนื่องจาก",
    angle_of_incidence: "มุมตกกระทบ [%]",
    spectral_effects: "เอฟเฟกต์สเปกตรัม [%]",
    temperature_and_low_irradiance: "อุณหภูมิและการฉายรังสีต่ำ [%]",
    total_loss: "สูญเสียทั้งหมด [%]",
    pv_electricity_cost: "ค่าไฟ PV [per kWh]",
    percentage_days_with_full_battery: "เปอร์เซ็นต์ของวันที่แบตเตอรี่เต็ม [%]",
    percentage_days_with_empty_battery: "เปอร์เซ็นต์วันที่แบตเตอรี่หมด [%]",
    average_energy_not_captured: "ไม่ได้รับพลังงานเฉลี่ย [Wh]",
    average_energy_missing: "พลังงานเฉลี่ยที่ขาดหายไป [Wh]",
    simulation_outputs: "เอาต์พุตการจำลอง",
    monthly_performance: "ประสิทธิภาพรายเดือน",
    percentile: "เปอร์เซ็นต์ไทล์",
    pv_technology_dropdown_unknown: "ไม่ทราบ",
    mounting_position_dropdown_free_standing: "ยืนฟรี",
    mounting_position_dropdown_roof_added_building_integrated: "ต่อเติมหลังคา / รวมอาคาร",
    optimize_dropdown_none: "ไม่มี",
    optimize_dropdown_slope: "ความชัน [°]",
    optimize_dropdown_slope_and_azimuth: "ความชัน [°] / ราบ [°]",
    monthly_performance_graph_pv_output: "เอาต์พุต PV [kWh]",
    monthly_performance_graph_radiation: "การฉายรังสี [kWh/m2]",
    monthly_performance_graph_energy_output: "การส่งออกพลังงาน [Wh]",
    monthly_performance_graph_energy_not_captured: "พลังงานไม่ถูกจับ [Wh]",
    monthly_performance_graph_days_with_battery_full: "วันที่แบตเตอรี่เต็ม [%]",
    monthly_performance_graph_days_with_battery_empty: "วันที่แบตเตอรี่หมด [%]",
    percentile_graph_charge_state_frequency: "ความถี่สถานะการชาร์จ [%]",
    tutorial1: (
      <>
        เลือกพื้นที่บนแผนที่ หลังจากยืนยันแล้ว ให้กด “แสดงผลลัพธ์”
        <br />
        เมื่อเลือกพื้นที่บนแผนที่
        ฐานข้อมูลที่ปรับให้เหมาะสมจะแสดงโดยอัตโนมัติในช่องฐานข้อมูลการแผ่รังสีของดวงอาทิตย์
      </>
    ),
    tutorial2: (
      <>
        ・การคำนวณประสิทธิภาพของระบบกริด PV
        <br />
        ในการคำนวณประสิทธิภาพให้ระบุตำแหน่งการติดตั้ง (พิกัด) ของแผงโซลาร์เซลล์บนแผนที่ “เทคโนโลยี
        PV” “เอาต์พุตสูงสุดที่ระบุ” “การสูญเสียระบบ” “ตำแหน่งการติดตั้ง” “มุมเอียง” อินพุตคือ
        จำเป็นสำหรับ “Azimuth” ตามเงื่อนไขนี้ รายการต่อไปนี้จะแสดงในกราฟ
        <br />
        <br />
        *สถิติการผลิตไฟฟ้ารายเดือน (ราย 1 ปี)
        <br />
        *สถิติรายเดือนของรังสีดวงอาทิตย์ (1 ปี)
      </>
    ),
    tutorial3: (
      <>
        ・การคำนวณประสิทธิภาพของระบบ PV แบบออฟกริด
        <br />
        ในการคำนวณประสิทธิภาพ ให้ระบุตำแหน่งการติดตั้ง (พิกัด) ของแผงโซลาร์เซลล์บนแผนที่
        “เอาต์พุตสูงสุดที่ระบุ” “ความจุของแบตเตอรี่” “ขีดจำกัดการคายประจุ” “การใช้พลังงานต่อวัน ”
        มุมเอียง” และ “มุม Azimuth” เป็นสิ่งจำเป็น ตามเงื่อนไขนี้ รายการต่อไปนี้จะแสดงในกราฟ
        <br />
        <br />
        *สถิติการผลิตไฟฟ้ารายเดือน (1 ปี) และการผลิตไฟฟ้าที่ไม่ได้จัดเก็บ (1 ปี)
        <br />
        *สถิติรายเดือนเกี่ยวกับความถี่ที่แบตเตอรี่ถึง 100% หรือ 0%
        <br />
        *ฮิสโตแกรมของการชาร์จแบตเตอรี่
      </>
    ),
  },
  VN: {
    grid_connected: "lưới kết nối",
    off_grid: "Ngoài lưới",
    input: "Đầu vào",
    solar_radiation_database: "cơ sở dữ liệu bức xạ mặt trời",
    installed_peak_pv_power_wp: "Công suất PV đỉnh đã lắp đặt [Wp]",
    pv_technology: "công nghệ pv",
    installed_peak_pv_power_kwp: "Công suất PV đỉnh đã lắp đặt [kWp]",
    system_loss: "Mất hệ thống [%]",
    fixed_mounting_options: "Tùy chọn gắn cố định",
    mounting_position: "Vị trí lắp đặt",
    slope: "Độ dốc [°]",
    azimuth: "Phương vị [°]",
    optimize: "Tối ưu hóa",
    pv_electricity_price: "giá điện pv",
    pv_system_cose: "Chi phí hệ thống PV ( --------- hiện tại của bạn)",
    interest: "Lãi suất [%/năm]",
    lifetime: "Tuổi thọ [năm]",
    battery_capacity: "Dung lượng pin [Wh]",
    discharge_cutoff_limit: "Giới hạn ngưỡng xả [%]",
    consumption_per_day: "Lượng tiêu thụ mỗi ngày [Wh]",
    visualize_result: "HÌNH ẢNH KẾT QUẢ",
    slope_angle: "góc dốc [°]",
    azimuth_angle: "góc phương vị [°]",
    yearly_pv_energy_production: "Sản xuất năng lượng PV hàng năm [kWh]",
    yearly_in_plane_irradiation: "Bức xạ trong máy bay hàng năm [kWh/m2]",
    year_to_year_variability: "Sự thay đổi hàng năm [kWh]",
    changes_in_output_due_to: "Thay đổi sản lượng do",
    angle_of_incidence: "Góc tới [%]",
    spectral_effects: "hiệu ứng quang phổ [%]",
    temperature_and_low_irradiance: "Nhiệt độ và bức xạ thấp [%]",
    total_loss: "Tổng thiệt hại [%]",
    pv_electricity_cost: "chi phí điện PV [per kWh]",
    percentage_days_with_full_battery: "Phần trăm ngày đầy pin [%]",
    percentage_days_with_empty_battery: "Phần trăm ngày hết pin [%]",
    average_energy_not_captured: "Năng lượng trung bình không thu được [Wh]",
    average_energy_missing: "Năng lượng trung bình thiếu [Wh]",
    simulation_outputs: "Đầu ra mô phỏng",
    monthly_performance: "Hiệu suất hàng tháng",
    percentile: "phần trăm",
    pv_technology_dropdown_unknown: "không xác định",
    mounting_position_dropdown_free_standing: "đứng tự do",
    mounting_position_dropdown_roof_added_building_integrated: "Thêm mái nhà / Tòa nhà tích hợp",
    optimize_dropdown_none: "Không có",
    optimize_dropdown_slope: "Độ dốc [°]",
    optimize_dropdown_slope_and_azimuth: "Độ dốc [°] / Phương vị [°]",
    monthly_performance_graph_pv_output: "เđầu ra PV [kWh]",
    monthly_performance_graph_radiation: "Sự bức xạ [kWh/m2]",
    monthly_performance_graph_energy_output: "năng lượng đầu ra [Wh]",
    monthly_performance_graph_energy_not_captured: "Năng lượng không bị bắt [Wh]",
    monthly_performance_graph_days_with_battery_full: "Số ngày đầy pin [%]",
    monthly_performance_graph_days_with_battery_empty: "Số ngày hết pin [%]",
    percentile_graph_charge_state_frequency: "Tần số trạng thái sạc [%]",
    tutorial1: (
      <>
        Chọn một khu vực trên bản đồ. Sau khi xác nhận, nhấn “Trực quan hóa kết quả.”
        <br />
        Bằng cách chọn khu vực trên bản đồ, cơ sở dữ liệu được tối ưu hóa sẽ tự động được phản ánh
        trên trường cơ sở dữ liệu bức xạ mặt trời.
      </>
    ),
    tutorial2: (
      <>
        ・Tính toán hiệu suất của hệ thống điện mặt trời dạng lưới
        <br />
        Để tính toán hiệu suất, hãy chỉ định vị trí lắp đặt (tọa độ) của tấm pin mặt trời trên bản
        đồ, “công nghệ PV”, “sản lượng tối đa danh nghĩa”, “tổn thất hệ thống”, “vị trí lắp đặt”,
        “góc nghiêng”, Đầu vào là cần thiết cho “Azimuth”. Dựa trên điều kiện này, các mục sau được
        hiển thị trong biểu đồ.
        <br />
        <br />
        *Số liệu thống kê phát điện hàng tháng (trong một năm)
        <br />
        *Số liệu thống kê hàng tháng về bức xạ mặt trời (trong một năm)
      </>
    ),
    tutorial3: (
      <>
        ・Tính toán hiệu suất của hệ thống PV ngoài lưới
        <br />
        Để tính toán hiệu suất, hãy chỉ định vị trí lắp đặt (tọa độ) của tấm pin mặt trời trên bản
        đồ, “sản lượng tối đa danh nghĩa”, “dung lượng pin”, “giới hạn cắt xả”, “mức tiêu thụ điện
        mỗi ngày , “góc nghiêng”, và “Góc phương vị” là bắt buộc. Dựa trên điều kiện này, các mục
        sau được hiển thị trong biểu đồ.
        <br />
        <br />
        *Thống kê phát điện hàng tháng (1 năm) và phát điện không lưu trữ (1 năm)
        <br />
        *Số liệu thống kê hàng tháng về tần suất pin đạt 100% hoặc 0%
        <br />
        *Biểu đồ sạc pin
      </>
    ),
  },
  ID: {
    grid_connected: "Jaringan Terhubung",
    off_grid: "Off-Grid",
    input: "Memasukkan",
    solar_radiation_database: "Database radiasi matahari",
    installed_peak_pv_power_wp: "Daya puncak PV terpasang [Wp]",
    pv_technology: "teknologi PV",
    installed_peak_pv_power_kwp: "Daya puncak PV terpasang [kWp]",
    system_loss: "Kehilangan sistem [%]",
    fixed_mounting_options: "Opsi Pemasangan Tetap",
    mounting_position: "Posisi pemasangan",
    slope: "Kemiringan [°]",
    azimuth: "Azimuth [°]",
    optimize: "Optimalkan",
    pv_electricity_price: "harga listrik PV",
    pv_system_cose: "Biaya sistem PV (Anda saat ini ---------)",
    interest: "Bunga [%/tahun]",
    lifetime: "Seumur hidup [tahun]",
    battery_capacity: "Kapasitas baterai [Wh]",
    discharge_cutoff_limit: "Batas batas pembuangan [%]",
    consumption_per_day: "Konsumsi per hari [Wh]",
    visualize_result: "VISUALISASIKAN HASIL",
    slope_angle: "Sudut kemiringan [°]",
    azimuth_angle: "Sudut azimuth [°]",
    yearly_pv_energy_production: "Produksi energi PV tahunan [kWh]",
    yearly_in_plane_irradiation: "Iradiasi dalam pesawat tahunan [kWh/m2]",
    year_to_year_variability: "Variabilitas dari tahun ke tahun [kWh]",
    changes_in_output_due_to: "Perubahan output karena",
    angle_of_incidence: "Sudut kejadian [%]",
    spectral_effects: "Efek spektral [%]",
    temperature_and_low_irradiance: "Suhu dan radiasi rendah [%]",
    total_loss: "Kerugian total [%]",
    pv_electricity_cost: "biaya listrik PV [per kWh]",
    percentage_days_with_full_battery: "Persentase hari dengan baterai penuh [%]",
    percentage_days_with_empty_battery: "Persentase hari dengan baterai kosong [%]",
    average_energy_not_captured: "Energi rata-rata tidak ditangkap [Wh]",
    average_energy_missing: "Energi rata-rata yang hilang [Wh]",
    simulation_outputs: "Output simulasi",
    monthly_performance: "Kinerja Bulanan",
    percentile: "Persentil",
    pv_technology_dropdown_unknown: "Tidak dikenal",
    mounting_position_dropdown_free_standing: "Berdiri bebas",
    mounting_position_dropdown_roof_added_building_integrated:
      "Penambahan atap / Bangunan terintegrasi",
    optimize_dropdown_none: "Tidak ada",
    optimize_dropdown_slope: "Kemiringan [°]",
    optimize_dropdown_slope_and_azimuth: "Kemiringan [°] / Azimuth [°]",
    monthly_performance_graph_pv_output: "keluaran PV [kWh]",
    monthly_performance_graph_radiation: "Radiasi [kWh/m2]",
    monthly_performance_graph_energy_output: "Keluaran energi [Wh]",
    monthly_performance_graph_energy_not_captured: "Energi tidak ditangkap [Wh]",
    monthly_performance_graph_days_with_battery_full: "Hari-hari dengan baterai penuh [%]",
    monthly_performance_graph_days_with_battery_empty: "Hari-hari dengan baterai kosong [%]",
    percentile_graph_charge_state_frequency: "Mengisi frekuensi status [%]",
    tutorial1: (
      <>
        Pilih area di peta. Setelah konfirmasi, tekan “Visualisasikan Hasil”.
        <br />
        Dengan memilih area pada peta, basis data yang dioptimalkan akan secara otomatis
        direfleksikan pada bidang basis data radiasi matahari.
      </>
    ),
    tutorial2: (
      <>
        ・Perhitungan kinerja sistem grid PV
        <br />
        Untuk menghitung kinerja, tentukan posisi pemasangan (koordinat) panel surya di peta,
        “teknologi PV”, “output maksimum nominal”, “kerugian sistem”, “posisi pemasangan”, “sudut
        kemiringan”, Masukan adalah diperlukan untuk “Azimuth”. Berdasarkan kondisi ini, item
        berikut ditampilkan dalam grafik.
        <br />
        <br />
        *Statistik bulanan pembangkit listrik (selama satu tahun)
        <br />
        *Statistik bulanan radiasi matahari (selama satu tahun)
      </>
    ),
    tutorial3: (
      <>
        ・Perhitungan kinerja sistem PV off-grid
        <br />
        Untuk menghitung kinerja, tentukan posisi pemasangan (koordinat) panel surya di peta,
        “output maksimum nominal”, “kapasitas baterai”, “batas pelepasan muatan”, “konsumsi daya per
        hari”, “Sudut kemiringan”, dan “Sudut azimuth” diperlukan. Berdasarkan kondisi ini, item
        berikut ditampilkan dalam grafik.
        <br />
        <br />
        *Statistik bulanan pembangkit listrik (1 tahun) dan pembangkit listrik tidak tersimpan (1
        tahun)
        <br />
        *Statistik bulanan tentang seberapa sering baterai mencapai 100% atau 0%
        <br />
        *Histogram pengisian baterai
      </>
    ),
  },
};

export default translation;
